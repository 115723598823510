import { ActionTypes } from '../actions'
import mixpanel from 'mixpanel-browser'

const defaultState = {
  isSignedIn: false,
  user: null,
}

const UserReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SIGNED_IN:
      return Object.assign({}, state, {
        isSignedIn: action.payload,
      })
    case ActionTypes.SET_USER:
      // Initialize Mixpanel on setting user
      if (window.location.hostname === 'localhost') {
        mixpanel.init('ddf52ed93dbdca563d9e41cb72f87dd6')
        console.log('Mixpanel init in College Pulse Local')
      } else {
        // On production, use the /mixpanel redirect.
        mixpanel.init('6f24e5d7e84c4e367451d45bd7123131', {
          api_host: window?.location?.search?.includes('wayup') ? undefined : '/mixpanel',
        })
        mixpanel.set_config({ disable_notifications: true })
      }

      // Assign the user to the mixpanel identity
      mixpanel.identify(action.payload.id)

      // Return the user to Redux state
      return Object.assign({}, state, {
        user: action.payload,
      })

    case ActionTypes.SET_LOGIN_ERROR:
      return Object.assign({}, state, {
        loginError: action.payload,
      })
    default:
      return state
  }
}
export default UserReducer
