import axios from 'axios'
import prefix from './prefix'
import urlJoin from 'url-join'

export default function (options) {
  if (options.extension && !options.uri) {
    options.url = urlJoin(prefix, options.extension)
  }

  return axios(options)
}
