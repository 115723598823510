import _ from 'lodash'
import * as modals from './modals'
import * as user from './user'
import * as brandReports from './brandReports'

const individualActionTypes = [modals, user, brandReports].map(
  actionModule => actionModule.ActionTypes
)

export let ActionTypes = {}

individualActionTypes.forEach(types => {
  _.assign(ActionTypes, types)
  Object.assign({}, ActionTypes, types)
})
