import authenticatedRequest from '../../lib/authenticatedRequest'

export const ActionTypes = {
  SET_BRAND_REPORTS: 'SET_BRAND_REPORTS',
  SET_BRAND_REPORT: 'SET_BRAND_REPORT',
}

export function myBrandReports() {
  return dispatch => {
    return authenticatedRequest({
      extension: '/brand-tracker/mine',
      responseType: 'json',
      method: 'get',
    }).then(res => {
      if (Array.isArray(res.data)) {
        return dispatch({
          type: ActionTypes.SET_BRAND_REPORTS,
          payload: res.data,
        })
      }
      dispatch({
        type: ActionTypes.SET_BRAND_REPORT,
        payload: res.data,
      })
    })
  }
}
